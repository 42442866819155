<template>
    <div>
        <v-img :src="src" 
            style="width:100px;" 
            @touchstart="active=true" 
            @touchend.stop.prevent="active=false;$emit('ClickNo')"  
            @click.stop.prevent="$emit('ClickNo')"  
            class="mx-auto">             
        </v-img>      
    </div>
</template>
<script>
export default {
    props: {
        routeName: String
    },
    data () { return {
        active: false,
    }},
    computed: {
        src: function () { 
            return !this.active ? '/img/no_1.svg' : '/img/no_2.svg'
        }
    },
}
</script>